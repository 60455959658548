import React, {FC, SVGAttributes} from 'react';

import {colors} from '../constants/colors';

const LaunchpadLogoSquare: FC<SVGAttributes<SVGElement>> = ({fill = colors.GRAY_900, ...props}) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' fill='none' {...props}>
    <rect width='64' height='64' rx='13.75' fill={fill} />
    <g clipPath='url(#clip0_2642_405)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.2853 31.9784C49.2853 37.4893 44.7816 41.9568 39.2259 41.9568C36.4772 41.9568 33.986 40.8631 32.1703 39.0906C30.3497 40.8887 27.8393 42.0001 25.0672 42.0001C19.5072 42.0001 15 37.5292 15 32.014C15 26.4987 19.5072 22.0278 25.0672 22.0278C27.821 22.0278 30.3165 23.1245 32.1341 24.9017C33.9527 23.1082 36.4589 22 39.2259 22C44.7816 22 49.2853 26.4675 49.2853 31.9784ZM25.0589 39.0206C28.9618 39.0206 32.1258 35.8821 32.1258 32.0106C32.1258 28.1391 28.9618 25.0006 25.0589 25.0006C21.156 25.0006 17.9921 28.1391 17.9921 32.0106C17.9921 35.8821 21.156 39.0206 25.0589 39.0206Z'
        fill={colors.THEME_GREEN}
      />
    </g>
    <defs>
      <clipPath id='clip0_2642_405'>
        <rect width='34.2853' height='22.0001' fill='white' transform='translate(15 21)' />
      </clipPath>
    </defs>
  </svg>
);

export default LaunchpadLogoSquare;
