import React, {SVGAttributes} from 'react';

const DesktopOnly: React.FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='76'
      height='64'
      viewBox='0 0 76 64'
      fill='none'
      {...props}
    >
      <path
        d='M73.6332 49.0837C73.6332 50.8437 72.2082 52.2837 70.4665 52.2837H5.51823C3.77656 52.2837 2.35156 50.8437 2.35156 49.0837V4.80107C2.35156 3.04107 3.77656 1.60107 5.51823 1.60107H70.4655C72.2072 1.60107 73.6322 3.04107 73.6322 4.80107L73.6332 49.0837Z'
        stroke='black'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.35156 46.668H73.6332'
        stroke='black'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.0705 52.2686L23.8125 61.7352'
        stroke='black'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M44.9258 52.2686L52.1838 61.7352'
        stroke='black'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.2148 62.4019H57.7982'
        stroke='black'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.6484 24.5924L32.3936 36.266L55.3678 12.7993'
        stroke='black'
        strokeWidth='2.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DesktopOnly;
