import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: string;
}

const SocialTwitter: FC<iconProps> = ({color = colors.GRAY_900, size = '25px', ...props}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1791_46510)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2 9.35156C19.2 15.6516 15.6 20.1516 9.3 20.1516C5.7 20.1516 4.257 18.2726 3 16.5516M3 16.5516C3.029 16.5466 5.7 15.6516 5.7 15.6516C2.694 12.6126 2.466 8.11156 4.8 4.85156C5.907 6.91056 7.974 8.81056 10.2 9.35156C10.286 6.75156 12.049 4.85156 14.7 4.85156C16.505 4.85156 17.567 5.53956 18.3 6.65156H21L19.2 9.35156'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1791_46510'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SocialTwitter;
