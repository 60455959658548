import './assets/css/App.scss';

import React, {Suspense} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {RecoilRoot} from 'recoil';

import * as Constants from './Helpers/constants/index';

const Loading = React.lazy(() => import('./Components/loading/Loading'));
const Layout = React.lazy(() => import('./Components/Layout/layout/Layout'));
const Page404 = React.lazy(() => import('./views/pages/Page404'));

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path={Constants.Pages.Stake.path} element={Constants.Pages.Stake.element} />
            <Route path='' element={<Layout />}>
              <Route
                index
                path={Constants.Pages.Home.path}
                element={Constants.Pages.Home.element}
              />
              <Route
                path={Constants.Pages.PrivacyPolicy.path}
                element={Constants.Pages.PrivacyPolicy.element}
              />
              <Route
                path={Constants.Pages.TermsOfUse.path}
                element={Constants.Pages.TermsOfUse.element}
              />
              <Route path='*' element={<Page404 />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
