import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: string;
  classes?: string;
}

const SocialTelegram: FC<iconProps> = ({
  color = colors.GRAY_900,
  size = '28px',
  classes = '',
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1791_46513)'>
      <path
        d='M11.7969 16.9066L9.73191 18.9156C9.37391 19.2636 8.77491 19.1096 8.62991 18.6316L7.25391 14.1016'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1053 10.9275C14.1053 10.9275 11.5163 13.2635 10.1883 14.4625C9.7913 14.8205 9.8243 15.4505 10.2543 15.7675L15.6323 19.7405C16.1613 20.1315 16.9173 19.8445 17.0543 19.2005L19.6953 6.75348C19.8233 6.15148 19.2323 5.64848 18.6583 5.86948L3.1433 11.8535C2.6863 12.0295 2.7083 12.6825 3.1753 12.8285L7.2523 14.1005'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1791_46513'>
        <rect width={size} height={size} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SocialTelegram;
