import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';

import CommonButton from '../../../../Components/CommonButton';

import {Pages} from '../../../../Helpers/constants';

import styles from './HomeHeader.module.scss';
import {HeaderLogo} from 'Components/icons';
import classNames from 'classnames';

function HomeHeader() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({top: element.offsetTop - 124, behavior: 'smooth'});
    }
  };

  return (
    <Navbar collapseOnSelect expand='lg' className={styles.navigation}>
      <div className={styles.navItems}>
        <div className={styles.logo}>
          <Link to='/' className={styles.logoLink}>
            <HeaderLogo />
          </Link>
        </div>

        <Navbar.Collapse>
          <div className={styles.linksWrapper}>
            <Nav className={styles.navLink}>
              <Nav.Link
                title={t('Rewards')}
                className={styles.link}
                onClick={() => handleClickScroll('home-section-reward')}
              >
                {t('Rewards')}
              </Nav.Link>
              <Nav.Link
                title={t('Requirements')}
                className={styles.link}
                onClick={() => handleClickScroll('home-section-requirements')}
              >
                {t('Requirements')}
              </Nav.Link>
              <Nav.Link
                title={t('FAQ')}
                className={styles.link}
                onClick={() => handleClickScroll('home-section-faq')}
              >
                {t('FAQ')}
              </Nav.Link>
              <NavDropdown title={'Testnet'} className={styles.link}>
                <NavDropdown.Item disabled={true} href='#' className={styles.dropDownItem}>
                  Mainnet
                </NavDropdown.Item>
                <NavDropdown.Item href='#' className={styles.dropDownItem}>
                  Testnet
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
        </Navbar.Collapse>
        <NavDropdown title={'Testnet'} className={classNames(styles.link, {[styles.mobile]: true})}>
          <NavDropdown.Item disabled={true} className={styles.dropDownItem} href='#'>
            Mainnet
          </NavDropdown.Item>
          <NavDropdown.Item className={styles.dropDownItem} href='#'>
            Testnet
          </NavDropdown.Item>
        </NavDropdown>
        <CommonButton
          onClick={() => navigate(Pages.Stake.path)}
          text={t('Become a validators').toUpperCase()}
          classes={styles.header}
          height={40}
          marginTop={0}
          marginBottom={0}
        />
      </div>
    </Navbar>
  );
}

export default HomeHeader;
