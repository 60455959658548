import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: string;
}

const ArrowRight: FC<iconProps> = ({color = colors.MAIN_GREEN, size = '22px', ...props}) => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{height: `${size}`, width: `${size}`}}
    transform='scale(0.8)'
    {...props}
  >
    <path
      d='M1.333 6h9.333m0 0L6 1.333M10.666 6L6 10.667'
      stroke={color}
      strokeWidth={1.33333}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ArrowRight;
