import React, {FC, SVGAttributes} from 'react';

const HeaderLogo: FC<SVGAttributes<SVGElement>> = ({fill = '#1F231E', ...props}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='44'
    height='28'
    viewBox='0 0 44 28'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_2642_20781)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.0004 13.8065C44.0004 20.8793 38.2203 26.6129 31.0901 26.6129C27.5624 26.6129 24.3651 25.2094 22.0349 22.9346C19.6985 25.2413 16.4773 26.667 12.9205 26.667C5.78469 26.667 0 20.9289 0 13.8505C0 6.77215 5.78469 1.03402 12.9205 1.03402C16.4546 1.03402 19.6573 2.44153 21.99 4.72228C24.324 2.42161 27.5397 1 31.0901 1C38.2203 1 44.0004 6.73365 44.0004 13.8065ZM12.9215 22.8474C17.9307 22.8474 21.9914 18.8193 21.9914 13.8505C21.9914 8.88158 17.9307 4.85352 12.9215 4.85352C7.91231 4.85352 3.85156 8.88158 3.85156 13.8505C3.85156 18.8193 7.91231 22.8474 12.9215 22.8474Z'
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id='clip0_2642_20781'>
        <rect width='44' height='28' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default HeaderLogo;
