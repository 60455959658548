import React from 'react';

import PrivacyPolicy from '../../views/pages/legal/PrivacyPolicy';
import TermsOfUse from '../../views/pages/legal/TermsOfUse';
import _DepositContractApi from './depositContractApi';

const Home = React.lazy(() => import('../../views/pages/home/Home'));
const Stake = React.lazy(() => import('../../views/pages/stake'));

export const Pages: TPages = {
  Home: {
    Title: 'Home',
    path: '',
    element: <Home />,
  },
  Stake: {
    Title: 'Stake',
    path: '/stake',
    element: <Stake />,
  },
  PrivacyPolicy: {
    Title: 'Privacy Policy',
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  TermsOfUse: {
    Title: 'Terms Of Use',
    path: '/terms-of-use',
    element: <TermsOfUse />,
  },
};

export const DEPOSIT_PER_VALIDATOR = Number(process.env.REACT_APP_DEPOSIT_PER_VALIDATOR);

export const DEPOSIT_CONTRACT_ADDRESS = process.env.REACT_APP_DEPOSIT_CONTRACT_ADDRESS;
export const DEPOSIT_CONTRACT_ABI = _DepositContractApi;

export enum DepositStatus {
  VERIFYING,
  ALREADY_DEPOSITED,
  READY_FOR_DEPOSIT,
}

export enum TransactionStatus {
  'READY',
  'PENDING',
  'STARTED',
  'SUCCEEDED',
  'FAILED',
  'REJECTED',
}

export interface DepositKeyInterface {
  pubkey: string;
  withdrawal_credentials: string;
  amount: number;
  signature: string;
  deposit_message_root: string;
  deposit_data_root: string;
  fork_version: string;
  deposit_cli_version: string;
  transactionStatus: TransactionStatus;
  txHash?: string;
  depositStatus: DepositStatus;
}
