import {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Text from '../../../Components/Text';
import termsPath from './termsOfUse.md';
import HomeHeader from '../home/components/HomeHeader';

const TermsOfUse = () => {
  const [state, setState] = useState('');

  useEffect(() => {
    fetch(termsPath)
      .then((response) => response.text())
      .then((text) => {
        setState(text);
      });
  }, []);
  return (
    <>
      <HomeHeader />
      <div className='page-content'>
        <div style={{margin: '4% 16% 16% 16%'}}>
          <Text>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{state}</ReactMarkdown>
          </Text>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
