import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: number;
}

const LaunchpadLogo: FC<iconProps> = ({color = colors.GRAY_900, size = 50, ...props}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 41 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M30.8831 0C28.3005 0 25.718 1.20312 22.9202 3.5C24.2115 4.70312 25.6104 6.5625 27.3321 8.3125C28.3005 7.54687 29.5918 6.5625 30.6679 6.5625C32.9276 6.5625 34.7569 8.3125 34.7569 10.3906C34.7569 12.6875 32.9276 14.3281 30.4527 14.3281C28.8386 14.3281 27.2245 12.6875 25.718 11.2656L25.2876 10.8281C24.5343 10.0625 23.8887 9.40625 23.0278 8.53125L22.4898 7.98437C22.167 7.54687 21.7365 7.21875 21.4137 6.78125C18.6159 3.71875 15.2801 0 10.3302 0C4.62709 0 0 4.70312 0 10.5C0 16.5156 4.51948 21 10.6531 21C13.0204 21 15.3877 20.125 17.7551 18.2656C16.5714 16.7344 14.3117 14 13.7736 13.3438C12.6976 14.1094 11.6215 14.4375 10.7607 14.4375C8.28571 14.4375 6.564 12.7969 6.564 10.3906C6.564 8.20312 8.39332 6.5625 10.5454 6.5625C12.1595 6.5625 13.5584 7.76563 15.2801 9.51563C15.8182 10.0625 16.3562 10.7187 16.8942 11.375C17.3247 11.8125 17.7551 12.3594 18.1855 12.7969C18.5083 13.2344 18.9388 13.5625 19.2616 14C19.7996 14.6562 20.4453 15.3125 21.0909 16.0781C24.2115 19.3594 27.4397 21 30.4527 21C36.4786 21 40.9981 16.5156 40.9981 10.5C41.1057 4.70312 36.5862 0 30.8831 0Z'
      fill={color}
    />
  </svg>
);

export default LaunchpadLogo;
