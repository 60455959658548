import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: string;
}

const SocialFacebook: FC<iconProps> = ({color = colors.GRAY_900, size = '25px', ...props}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.5 14H16.2498C16.3613 14 16.4696 13.9627 16.5575 13.8941C16.6453 13.8255 16.7078 13.7294 16.7348 13.6213L17.4848 10.6213C17.5032 10.5476 17.5046 10.4706 17.4889 10.3963C17.4731 10.3219 17.4406 10.2522 17.3939 10.1923C17.3471 10.1324 17.2873 10.084 17.219 10.0506C17.1507 10.0173 17.0757 10 16.9998 10H13.5V8C13.5 7.73478 13.6054 7.48043 13.7929 7.29289C13.9804 7.10536 14.2348 7 14.5 7H17C17.1326 7 17.2598 6.94732 17.3536 6.85355C17.4473 6.75979 17.5 6.63261 17.5 6.5V3.5C17.5 3.36739 17.4473 3.24021 17.3536 3.14645C17.2598 3.05268 17.1326 3 17 3H14.5C13.1739 3 11.9021 3.52678 10.9645 4.46447C10.0268 5.40215 9.5 6.67392 9.5 8V10H7C6.86739 10 6.74021 10.0527 6.64645 10.1464C6.55268 10.2402 6.5 10.3674 6.5 10.5V13.5C6.5 13.6326 6.55268 13.7598 6.64645 13.8536C6.74021 13.9473 6.86739 14 7 14H9.5V20.5C9.5 20.6326 9.55268 20.7598 9.64645 20.8536C9.74021 20.9473 9.86739 21 10 21H13C13.1326 21 13.2598 20.9473 13.3536 20.8536C13.4473 20.7598 13.5 20.6326 13.5 20.5V14Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SocialFacebook;
