import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: string;
}

const SocialInstagram: FC<iconProps> = ({color = colors.GRAY_900, size = '25px', ...props}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1577_45986)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.496 3H16.505C18.987 3 21 5.012 21 7.496V16.505C21 18.987 18.988 21 16.504 21H7.496C5.013 21 3 18.988 3 16.504V7.496C3 5.013 5.012 3 7.496 3Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.9464 6.71484C16.7604 6.71584 16.6094 6.86684 16.6094 7.05284C16.6094 7.23884 16.7614 7.38984 16.9474 7.38984C17.1334 7.38984 17.2844 7.23884 17.2844 7.05284C17.2854 6.86584 17.1334 6.71484 16.9464 6.71484Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='17' cy='7' r='1' fill={color} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.544 9.45285C15.9499 10.8587 15.9499 13.1381 14.544 14.544C13.1381 15.9499 10.8587 15.9499 9.45285 14.544C8.04697 13.1381 8.04697 10.8587 9.45285 9.45285C10.8587 8.04697 13.1381 8.04697 14.544 9.45285Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1577_45986'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SocialInstagram;
