import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: string;
}

const SocialDiscord: FC<iconProps> = ({color = colors.GRAY_900, size = '25px', ...props}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17 16.5C15.4123 17.1489 13.7152 17.4883 12 17.5C10.2848 17.4883 8.58773 17.1489 7 16.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.3359 13.418C16.1644 13.418 16.8359 12.6345 16.8359 11.668C16.8359 10.7015 16.1644 9.91797 15.3359 9.91797C14.5075 9.91797 13.8359 10.7015 13.8359 11.668C13.8359 12.6345 14.5075 13.418 15.3359 13.418Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.66406 13.418C9.49249 13.418 10.1641 12.6345 10.1641 11.668C10.1641 10.7015 9.49249 9.91797 8.66406 9.91797C7.83564 9.91797 7.16406 10.7015 7.16406 11.668C7.16406 12.6345 7.83564 13.418 8.66406 13.418Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.30417 16.951C7.8392 18.016 7.27839 19.0365 6.62865 20C5.0594 19.503 3.56696 18.79 2.19425 17.8815H2.1961C1.55683 13.6513 2.49266 9.33361 4.82601 5.74769C6.16007 5.16098 7.5617 4.74189 8.99885 4.5C9.10431 4.85075 9.18908 5.20991 9.27371 5.56913C10.1758 5.42237 11.0879 5.34524 12.0019 5.33842C12.9158 5.34524 13.8279 5.42237 14.73 5.56913C14.8146 5.20991 14.8994 4.85075 15.0049 4.5C16.442 4.74189 17.8436 5.16098 19.1777 5.74769C21.5111 9.33361 22.4469 13.6513 21.8076 17.8815H21.8095C20.4368 18.79 18.9443 19.503 17.3751 20C16.7253 19.0365 16.1645 18.016 15.6995 16.951'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SocialDiscord;
