import React, {FC} from 'react';

import {colors} from '../constants/colors';

interface iconProps {
  color?: string;
  size?: number;
}

const ArrowDown: FC<iconProps> = ({color = colors.GRAY_900, size = 50, ...props}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 1l4 4 4-4'
      stroke={color}
      strokeWidth={1.33}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ArrowDown;
